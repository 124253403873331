<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('settings.destination.new.title')"
        :sub-title="$t('settings.destination.new.desc')"
    />
    <section class="wameed-dashboard-page-content_body">
      <b-row class="px-2">
        <b-col
            lg="12"
            md="12"
            class="px-0"
        >
          <b-card
              no-body
              class="cursor-pointer wameed-card"
          >
            <b-card-header class="align-items-start tab-title">
              <h5 class="text-reg-18 text-font-main">
                {{ $t('settings.destination.new.title') }}
              </h5>
            </b-card-header>
            <wameed-form
                refs=""
                @submitAction="submitdestination"
            >
              <template
                  slot="form-content"
                  slot-scope="{ invalid }"
              >
                <b-card-body
                    class="position-relative py-5 justify-content-between px-5"
                >
                  <!-- form -->
                  <div class="d-flex flex-wrap py-4">

                    <b-col md="6">
                      <div @click="$refs.imageinput.click()">
                        <text-input
                            id="main-image"
                            v-model="form.image"
                            readonly
                            name="image"
                            rules="required"
                            :label="$t('settings.destination.form.image')"
                            :placeholder="$t('packages.main_image_placeholder')"
                            is-append
                            append-class="append-btn"
                            :append-text="$t('packages.browse')"
                        />
                      </div>
                      <input
                          ref="imageinput"
                          type="file"
                          accept="image/png, image/jpeg"
                          style="display: none"
                          @change="uploadImageAction($event)"
                      />
                    </b-col>
                    <b-col
                        cols="12"
                        md="6"
                    >
                      <text-input
                          id="destination-name-ar"
                          v-model="form.name"
                          :label="$t('settings.destination.form.name')"
                          input-classes="text-med-14 text-font-main"
                          name="name"
                          rules="required"
                          :placeholder="$t('settings.destination.form.name_placeholder')"
                      />
                    </b-col>

                    <b-col
                        cols="12"
                        md="12"
                    >
                      <text-area
                          id="destination-desc"
                          v-model="form.desc"
                          :label="$t('settings.destination.form.desc')"
                          input-classes="text-med-14 text-font-main"
                          name="desc"
                          rules="required"
                          rows="5"
                          :placeholder="$t('settings.destination.form.desc_placeholder')"
                      />
                    </b-col>

                  </div>
                </b-card-body>
                <b-card-footer class="px-4 py-3">
                  <div class="d-flex flex-wrap justify-content-between">
                    <div class="px-4 py-3">
                      <wameed-btn
                          classes="  text-med-14 text-white rounded-10"
                          :title="$t('common.add')"
                          :disabled="invalid"
                          type="submit"
                          variant="main"
                      />
                    </div>
                    <div class="px-4 py-3">
                      <wameed-btn
                          classes="  text-med-14 text-font-secondary rounded-10 "
                          :title="$t('common.cancel')"
                          type="button"
                          variant="gray"
                          @submitAction="canceldestination"
                      />
                    </div>
                  </div>
                </b-card-footer>
              </template>
            </wameed-form>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import WameedForm from '@/components/WameedForm.vue';

import WameedBtn from '@/components/WameedBtn.vue';
import {mapActions} from 'vuex';

import TextInput from '@/components/TextInput.vue';
import PageHeader from '@/components/PageHeader.vue';
import TextArea from "@/components/TextArea";

export default {
  components: {
    TextArea,
    TextInput,
    WameedBtn,
    WameedForm,
    PageHeader,
  },
  data() {
    return {
      form: {
        image: '',
        name: '',
        desc: '',
      },
    };
  },

  methods: {
    ...mapActions({
      adddestination: 'addDestination',
      uploadFiles: 'uploadFiles',
    }),

    uploadImageAction(event, type = 'upload') {
      this.$store.commit('loadingStart', null);
      let files = [];
      if (type === 'upload') {
        files = event.currentTarget.files;
      } else {
        files = event;
      }
      // if (this.dropped == 0) files=event;
      // else files=event.currentTarget.files

      if (!files.length) return;
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i]);
        
        this.uploadFiles(formData).then(response => {
          this.form = {
            ...this.form,
            image: response,
          };
          this.$store.commit('loadingFinish');
        });
      }
    },

    canceldestination() {
      this.$router.push({name: 'destinations'});
    },
    submitdestination() {
      this.adddestination({
        image: this.form.image,
        name: this.form.name,
        content: this.form.desc
      })
    },
  },
};
</script>
